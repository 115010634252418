<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>love-it-ribbon-2</title>
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="4.717 13.152 0.75 18 4.5 18.75 6 23.25 9.99 16.918"
    />
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="19.283 13.152 23.25 18 19.5 18.75 18 23.25 14.01 16.918"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M12.471.916,14.318,2.4l2.369-.118a.75.75,0,0,1,.761.553l.62,2.289,1.986,1.3a.75.75,0,0,1,.29.9L19.5,9.537l.844,2.216a.749.749,0,0,1-.29.895l-1.986,1.3-.62,2.29a.75.75,0,0,1-.761.553l-2.369-.118-1.847,1.488a.752.752,0,0,1-.942,0L9.682,16.67l-2.369.118a.75.75,0,0,1-.761-.553l-.62-2.29-1.986-1.3a.749.749,0,0,1-.29-.895L4.5,9.537,3.656,7.32a.75.75,0,0,1,.29-.9l1.986-1.3.62-2.289a.75.75,0,0,1,.761-.553L9.682,2.4,11.529.916A.752.752,0,0,1,12.471.916Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M12,14.25l-3.835-4A2.268,2.268,0,0,1,7.74,7.63h0a2.269,2.269,0,0,1,3.634-.59L12,7.666l.626-.626a2.269,2.269,0,0,1,3.634.59h0a2.268,2.268,0,0,1-.425,2.619Z"
    />
  </svg>
</template>
